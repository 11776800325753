import { useI18n } from '@popety_io/popety-io-lib'
import TextField from '@mui/material/TextField'
import Grid from '@mui/material/Grid'
import { MenuItem, Select } from '@mui/material'
import CountriesSearch from '../Summary/components/FormCompany/CountriesSearch'

type CompanyInfosFormProps = {
  values: {
    lang?: any
    bexio_id?: string
    email_contact?: string
    name?: string
    address?: string
    postalCode?: string
    city?: string
    country?: string
    website?: string
    template_email_address?: string
  }
  onChange: (e: any) => void
  errors: {
    lang?: any
    bexio_id?: string
    email_contact?: string
    name?: string
    address?: string
    postalCode?: string
    city?: string
    country?: string
    website?: string
    template_email_address?: string
  }
}

const CompanyInfosForm = ({
  values,
  errors,
  onChange,
}: CompanyInfosFormProps) => {
  const { t } = useI18n()

  return (
    <>
      <Grid item xs={12} sm={6}>
        <TextField
          size="small"
          id="company-name"
          label={t('companyDashboard.Company name')}
          value={values?.name}
          onChange={onChange}
          name="name"
          fullWidth
          error={!!errors?.name}
          helperText={errors?.name}
          required
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          size="small"
          id="address"
          label={t('companyDashboard.Address')}
          value={values?.address}
          onChange={onChange}
          name="address"
          fullWidth
          error={!!errors?.address}
          helperText={errors?.address}
          required
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          size="small"
          id="postalCode"
          label={t('companyDashboard.Postal code')}
          value={values?.postalCode}
          onChange={onChange}
          name="postalCode"
          fullWidth
          error={!!errors?.postalCode}
          helperText={errors?.postalCode}
          required
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <TextField
          size="small"
          id="city"
          label={t('companyDashboard.City')}
          value={values?.city}
          onChange={onChange}
          name="city"
          fullWidth
          error={!!errors?.city}
          helperText={errors?.city}
          required
        />
      </Grid>

      <Grid item xs={12} sm={6}>
        <CountriesSearch
          label={t('companyDashboard.Country')}
          value={values?.country as any}
          onChange={(value: any) =>
            onChange({ target: { name: 'country', value } })
          }
          error={!!errors?.country}
          helperText={errors?.country}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          size="small"
          id="website"
          label={t('companyDashboard.Website')}
          value={values?.website}
          onChange={onChange}
          name="website"
          fullWidth
          error={!!errors?.website}
          helperText={errors?.website}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          size="small"
          id="email-address"
          label={t('companyDashboard.Template Email Address')}
          value={values?.template_email_address}
          onChange={onChange}
          name="template_email_address"
          fullWidth
          error={!!errors?.template_email_address}
          helperText={errors?.template_email_address}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          size="small"
          id="email_contact"
          label={t('companyDashboard.Billing Email')}
          value={values?.email_contact}
          onChange={onChange}
          name="email_contact"
          fullWidth
          error={!!errors?.email_contact}
          helperText={errors?.email_contact}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          size="small"
          id="lang"
          label={t('companyDashboard.Language (fr, de, it or en)')}
          value={values?.lang}
          onChange={onChange}
          name="lang"
          fullWidth
          error={!!errors?.lang}
          helperText={errors?.lang}
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        <TextField
          size="small"
          id="bexio_id"
          label={t('companyDashboard.Bexio Id (DO NOT CHANGE)')}
          value={values?.bexio_id}
          onChange={onChange}
          name="bexio_id"
          fullWidth
          error={!!errors?.bexio_id}
          helperText={errors?.bexio_id}
        />
      </Grid>
    </>
  )
}

export default CompanyInfosForm