/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { mapBy } from '@popety_io/popety-io-lib'
// import { updateOfficesById } from '../companies.utils'

import contractsServices from './contractsServices'

const initialState = {
  loading: false,
  contractsById: {},
}

export const getAllContractsByCompany = createAsyncThunk(
  'contractsServices/getAllContractsByCompany',
  contractsServices.getAllContractsByCompany,
)

// export const getSubscriptionsType = createAsyncThunk(
//   'contractsServices/getSubscriptionsType',
//   contractsServices.getSubscriptionsType,
// )

export const endSubscription = createAsyncThunk(
  'contractsServices/endSubscription',
  contractsServices.endSubscription,
)

export const addContract = createAsyncThunk(
  'contractsServices/addContract',
  contractsServices.addContract,
)

export const updateContract = createAsyncThunk(
  'contractsServices/updateContract',
  contractsServices.updateContract,
)

export const deleteContract = createAsyncThunk(
  'contractsServices/deleteContract',
  contractsServices.deleteContract,
)

export const addBilling = createAsyncThunk(
  'contractsServices/addBilling',
  contractsServices.addBilling,
)

export const updateBilling = createAsyncThunk(
  'contractsServices/updateBilling',
  contractsServices.updateBilling,
)

export const contracts = createSlice({
  name: 'contracts',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getAllContractsByCompany.pending, (state) => {
        state.loading = true
      })
      .addCase(getAllContractsByCompany.rejected, (state) => {
        state.loading = false
      })
      .addCase(getAllContractsByCompany.fulfilled, (state, action) => {
        const { data = [] } = action.payload || {}

        state.loading = false

        state.contractsById = mapBy('id', data)
      })
      // .addCase(getSubscriptionsType.fulfilled, (state, action) => {
      //   const { data } = action.payload || {}

      //   if (data) {
      //     state.subscriptionsType = data
      //   }
      // })
      .addCase(endSubscription.fulfilled, (state, { meta }) => {
        const input = meta.arg

        if (input.id) {
          state.contractsById[input.id] = {
            ...state.contractsById[input.id],
            ...input.body,
          }
        }
      })
      .addCase(addContract.fulfilled, (state, action) => {
        const { data } = action.payload || {}
        const { input } = action.meta.arg
        const { id } = data

        if (id) {
          state.contractsById = Object.keys(state.contractsById).map((key) => {
            return {
              ...state.contractsById[key],
              is_active: false,
            }
          })
          state.contractsById[id] = {
            ...state.contractsById[id],
            ...input,
            is_active: true,
            auto_renew: true,
            // options: [data.optionsData],
            id,
          }
        }
      })
      .addCase(updateContract.fulfilled, (state, action) => {
        const { data } = action.payload || {}
        const { input } = action.meta.arg
        const { id } = data

        if (input) {
          state.contractsById[input.id] = {
            ...state.contractsById[input.id],
            active: false,
          }
        }

        if (id) {
          state.contractsById[id] = {
            ...state.contractsById[id],
            ...data,
            id,
          }
        }
      })
      .addCase(deleteContract.fulfilled, (state, { meta }) => {
        const input = meta.arg

        if (input) {
          state.contractsById[input] = {}
        }
      })
      .addCase(addBilling.fulfilled, (state, action) => {
        const { input } = action.meta.arg
        const { company_plan_id } = input
        const { id, bexio_id } = action.payload.data

        if (company_plan_id) {
          state.contractsById[company_plan_id] = {
            ...state.contractsById[company_plan_id],
            options: [
              { ...input, id, bexio_id },
              // eslint-disable-next-line no-unsafe-optional-chaining
              ...state?.contractsById?.[company_plan_id]?.options,
            ],
          }
        }
      })
      .addCase(updateBilling.fulfilled, (state, action) => {
        const { input } = action.meta.arg
        const { company_plan_id } = input

        if (company_plan_id) {
          state.contractsById[company_plan_id] = {
            ...state.contractsById[company_plan_id],
            options: state?.contractsById?.[company_plan_id]?.options?.map(
              (item) => (item.id === input.id ? input : item),
            ),
          }
        }
      })
  },
})

export const contractsReducer = {
  contracts: contracts.reducer,
}
